import create from "zustand";

type StatusType = "idle" | "pulsing";

type UseAvatarState = {
  status: StatusType;
  updateStatus: (newStatus: StatusType) => void;
};

const useAvatarState = create<UseAvatarState>((set) => ({
  status: "idle",
  updateStatus: (newStatus: StatusType) => set({ status: newStatus })
}));

export default useAvatarState;
