import { createGlobalStyle } from "styled-components";
import "nprogress/nprogress.css";

const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  }

  :root {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    cursor: default;
    font-size: 0.625rem;
    line-height: 1.4;

    --grey: "#73737D";
  }

  /* html {
    scroll-behavior: smooth;
  } */

  body {
    font-family: 'Open Sans',
    '-apple-system',
    'BlinkMacSystemFont',
	  'San Francisco',
	  'Helvetica Neue',
    'Helvetica',
    'Ubuntu',
    'Roboto',
    'Noto',
    'Segoe UI',
    'Arial',
    sans-serif;
    font-size: 1.6rem;
    height: 100%;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    /* background: #F9ECEC; */
    /* background: white; */
    
    /* background: url('https://images.unsplash.com/photo-1458682625221-3a45f8a844c7'); */
    /* background: url('https://images.unsplash.com/photo-1488109811119-98431feb6929'); */
    /* background: url('https://images.unsplash.com/photo-1496863798882-c7af83261624'); */
    /* background: url('https://images.unsplash.com/photo-1486927181919-3ac1fc3a8082'); */
    background-size: cover;
  }

  h1 {
    font-size: clamp(3rem, 5vw, 4.15rem);
    line-height: 1;
  }

  button,
  a {
    text-decoration: none;
    cursor: pointer;
  }

  a {
    color: black;
  }

  a:focus {
    outline: none;
  }

  p {
    color: black;
    font-size: 1.8rem;
  }

  li {
    list-style: none;
  }
  
  [hidden] {
    display: none;
  }

  [unselectable] {
    user-select: none;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background-color: transparent;
    width: 100%;

    &::-ms-expand {
      display: none;
    }

    option {
      color: #262626;
    }
  }

  input, textarea, select, button {

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset !important;
    }
  }

  .underline {
    text-decoration: underline;
  }

  button,
  input,
  select,
  textarea {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace;
  }

  fieldset,
  button {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  audio:not([controls]) {
    display: none; 
  }

  details {
    display: block; 
  }

  input {
    &:focus,
    &:active {
      outline: none;
    }

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder, 
    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.25);
    }

    &[type="number"] {
      width: auto;
    }

    &[type="search"] {
      -webkit-appearance: textfield;
      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    
  }

  #nprogress {
      pointer-events: none;
    }

    #nprogress .bar {
      background: #635ec0; 
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;

      .peg {
        box-shadow: 0 0 10px #635ec0, 0 0 5px #635ec0;
      }
    }

    #nprogress .spinner .spinner-icon {
      border-top-color: #635ec0;
      border-left-color: #635ec0;
    }
`;

export default GlobalStyles;
