import styled from "styled-components";
import { motion } from "framer-motion";

export default styled(motion.div)<{
  type?: "row" | "column";
  gap?: number;
  center?: boolean;
  stretchColumns?: boolean;
  stretchRows?: boolean;
}>`
  display: grid;
  grid-auto-flow: ${(props) => (props.type === "column" ? "row" : "column")};
  grid-gap: ${(props) => `${props.gap ?? 0}rem`};
  justify-content: flex-start;
  align-items: center;
  position: relative;

  ${(p) =>
    p.center === true &&
    `
    justify-content: center;
  `}

  ${(p) =>
    p.stretchColumns === true &&
    `
    grid-template-columns: minmax(0, 1fr);
  `}

  ${(p) =>
    p.stretchRows === true &&
    `
    grid-template-rows: minmax(0, 1fr);
  `}
`;
