import React from "react";
import styled from "styled-components";
import { motion, AnimateSharedLayout } from "framer-motion";
import Navigation from "@sections/Navigation";
import useContactModal from "@hooks/useContactModal";
import { GlobalStyles } from "@styles";
import CookieConsent, { Cookies } from "react-cookie-consent";

interface LayoutProps {
  children: React.ReactNode;
  location: Location;
}

function Layout({ children, location }: LayoutProps) {
  const { showContactModal, toggleContact } = useContactModal();

  const showCursor =
    location.pathname.startsWith("/work") && location.pathname.length > 6;

  const isPrivacyPage = location.pathname.startsWith("/privacy");
  return (
    <Wrapper showContactModal={showContactModal}>
      <GlobalStyles />

      {!(
        location.pathname.startsWith("/vaccine") === true ||
        location.pathname.startsWith("/brief") === true
      ) && <Navigation toggleContact={toggleContact} />}
      {!isPrivacyPage ? (
          <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="hasAcceptedCookies"
          style={{ background: "#405359" }}
          buttonStyle={{ color: "#fff", background: "#635ec0" , padding: '10px 20px', borderRadius: '5px', fontSize: '1.8rem'}}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          <span style={{ fontSize: "10px", marginLeft: '20px' }}>
            <a href="/privacy" target={'_blank'} style={{color: '#fff'}}>View Privacy Policy</a>
          </span>
        </CookieConsent>
      ): null}
      <AnimateSharedLayout>
        <MainContent>{children}</MainContent>
      </AnimateSharedLayout>

      {/* <FooterContainer>
        <FooterText>Copyright 2020, Sympler</FooterText>
      </FooterContainer> */}
    </Wrapper>
  );
}

export default Layout;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  position: relative;

  ${(p: { showContactModal?: boolean }) =>
    p.showContactModal &&
    `
    overflow: hidden;
  `}
`;

const MainContent = styled(motion.main)`
  width: 100%;
`;

const FooterContainer = styled.div`
  width: 100%;
  padding: 4rem 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
`;

const FooterText = styled.div`
  font-size: 1.25rem;
  font-family: "Open Sans", sans-serif;
  color: #333;
  letter-spacing: 0.25px;
`;
