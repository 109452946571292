import { useState } from "react";
import { useTransform, MotionValue } from "framer-motion";

function useRange(range: [number, number], totalProgress: MotionValue<number>) {
  const localProgressValue = useTransform(totalProgress, range, [0, 1], {
    clamp: true
  });

  const opacity = useTransform(
    localProgressValue,
    [0, 0.1, 0.9, 1],
    [0, 1, 1, 0]
  );

  const [localProgress, setLocalProgress] = useState(0);

  localProgressValue.onChange(setLocalProgress);

  const shouldShow =
    totalProgress.get() >= range[0] && totalProgress.get() <= range[1];

  return [shouldShow, localProgress, opacity, localProgressValue] as const;
}

export default useRange;
