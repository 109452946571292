const React = require("react");
const { ThemeProvider } = require("styled-components");
const { ContactProvider } = require("../../src/context/contact");
const { theme } = require("../../src/styles");

module.exports = ({ element }) => (
  <ThemeProvider theme={theme}>
    <ContactProvider>{element}</ContactProvider>
  </ThemeProvider>
);
