const throttle = require("lodash/throttle");
const { clamp } = require("../../src/utils");

function getWindowDimensions() {
  if (typeof window !== "undefined") {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    return {
      height,
      width
    };
  }

  return {
    width: 0,
    height: 0
  };
}

/**
 * handleFadeInAndOutOnScroll()
 * Attaches a scroll event listener to the window and will query for each
 * How to use:
 * <Element data-scroll-fade{true} />
 *
 */

// eslint-disable-next-line
function handleFadeInAndOutOnScroll() {
  const handleScroll = throttle(() => {
    const { height } = getWindowDimensions();
    const elements = Array.from(
      document.querySelectorAll("[data-scroll-fade]")
    );

    elements.forEach((element) => {
      const box = element.getBoundingClientRect();

      if (box.top < height / 3.33) {
        // Fade out the element when it reaches the top 2/3 of the page
        element.style.opacity = clamp(
          (box.top + element.offsetHeight / 1.5) / (height / 3.33)
        );
      } else {
        // Fade in the element from the bottom of the page
        element.style.opacity = clamp((1 - box.top / height) * 1.66);
      }
    });
  }, 20);

  window.addEventListener("scroll", handleScroll);
}

module.exports = async () => {
  // handleFadeInAndOutOnScroll();

  if (typeof IntersectionObserver === "undefined") {
    await require("intersection-observer");
    console.log("IntersectionObserver polyfilled ;)");
  }
};
