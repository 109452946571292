const black = "#262626";
const grey = "#73737D";
const orbFill = "#c5c5c5";
const orbRings = "#f2f2f2";
const logo = "#ffffff";
const error = "#ff6347";
const brand = {
  pink: "linear-gradient(90deg, #f78dd3 0%, #ff7da9 54.28%)",
  orange: "linear-gradient(320.59deg, #FF777A 7.39%, #FC7D49 81.36%)",
  lightPurple: "linear-gradient(90deg, #DC9FEF 0%, #F58FD7 100%)",
  purple: "linear-gradient(140.59deg, #B3B3FF 18.64%, #D9A1F2 92.61%)",
  blue: "linear-gradient(50.59deg, #7ECEFD 20.28%, #91C2FF 94.25%)"
};

const theme = {
  colors: {
    black,
    grey,
    orbFill,
    orbRings,
    logo,
    error,
    brand
  },
  breakpoints: [
    ["phonesmall", 320],
    ["phone", 376],
    ["phablet", 540],
    ["tablet", 768],
    ["desktopsmall", 960],
    ["desktop", 1024],
    ["desktopmedium", 1280],
    ["desktoplarge", 1440]
  ]
};

export default theme;
