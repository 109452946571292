import { Machine } from "xstate";

export const toggleMachine = Machine({
  id: "toggle",
  initial: "hidden",
  states: {
    hidden: {
      on: { TOGGLE: "showing" }
    },
    showing: {
      on: { TOGGLE: "hidden" }
    }
  }
});
