// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-another-tsx": () => import("./../../../src/pages/another.tsx" /* webpackChunkName: "component---src-pages-another-tsx" */),
  "component---src-pages-avatar-tsx": () => import("./../../../src/pages/avatar.tsx" /* webpackChunkName: "component---src-pages-avatar-tsx" */),
  "component---src-pages-brief-tsx": () => import("./../../../src/pages/brief.tsx" /* webpackChunkName: "component---src-pages-brief-tsx" */),
  "component---src-pages-brief-view-tsx": () => import("./../../../src/pages/brief/view.tsx" /* webpackChunkName: "component---src-pages-brief-view-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pg-tsx": () => import("./../../../src/pages/pg.tsx" /* webpackChunkName: "component---src-pages-pg-tsx" */),
  "component---src-pages-play-tsx": () => import("./../../../src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-playtest-tsx": () => import("./../../../src/pages/playtest.tsx" /* webpackChunkName: "component---src-pages-playtest-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-vaccine-tsx": () => import("./../../../src/pages/vaccine.tsx" /* webpackChunkName: "component---src-pages-vaccine-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-study-tsx": () => import("./../../../src/templates/study.tsx" /* webpackChunkName: "component---src-templates-study-tsx" */)
}

