module.exports = ({ prevRouterProps, routerProps }) => {
  const prevPath = prevRouterProps?.location?.pathname ?? "";
  const currentPath = routerProps?.location?.pathname ?? "";

  const firstCondition = currentPath.startsWith("/work") && prevPath.length > 6;

  if (firstCondition && prevPath.startsWith("/work")) {
    return true;
  }

  return true;
};
