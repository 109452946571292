import { useEffect } from "react";

function useLockBodyScroll(toggle: boolean) {
  useEffect((): any => {
    document.body.style.overflow = toggle ? "hidden" : "visible";

    return () => (document.body.style.overflow = "visible");
  }, [toggle]); // Empty array ensures effect is only run on mount and unmount
}

export default useLockBodyScroll;
