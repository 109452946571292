export const clamp = (value: number, min: number, max: number) =>
  value < min ? min : value > max ? max : value;

export const minZeroMaxOne = (num: number) => clamp(num, 0, 1);

export const scale = (num: number, outMin: number, outMax: number) => {
  return ((num - 0) * (outMax - outMin)) / (1 - 0) + outMin;
};

export function getOffsetTop(element: HTMLElement) {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    // @ts-ignore TODO: Fix the typing for this
    element = element.offsetParent;
  }
  return offsetTop;
}

export function convertToSlug(text: string) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

export function mergeRefs(...refs: any) {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];

  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
}

export function formatBytes(bytes: number, decimals: number = 0) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const dictionary: { [key: number]: string } = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen"
};

export function getTime() {
  return new Date().toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });
}
