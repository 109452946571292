import { useContext } from "react";
import { ContactContext } from "@context/contact";

function useContact() {
  const context = useContext(ContactContext);
  if (context === undefined) {
    throw new Error("useContact must be used within a ContactProvider");
  }
  return context;
}

export default useContact;
