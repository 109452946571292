import React, { createContext } from "react";
import { toggleMachine } from "@machines/toggle";
import { useMachine } from "@xstate/react";

export const ContactContext = createContext({
  showContactModal: false,
  toggleContact: (event?: React.SyntheticEvent) => {}
});

export const ContactProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [current, send] = useMachine(toggleMachine);
  const showContactModal = current.matches("showing");

  function toggleContact(event?: React.SyntheticEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    send("TOGGLE");
  }

  return (
    <ContactContext.Provider value={{ showContactModal, toggleContact }}>
      {children}
    </ContactContext.Provider>
  );
};
