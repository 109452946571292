import create from 'zustand'

type LogoType = "Fanta" | "Headspace" | "Mars" | "Diageo" | "NBCUniversal" | "Google"

type UseHoverLogoProps = {
  hoverLogo: LogoType | null
  updateHoverLogo: (newLogo: LogoType| null) => void;
}

const useHoverLogo = create<UseHoverLogoProps>(set => ({
  hoverLogo: null,
  updateHoverLogo: (newLogo: LogoType | null) => set({
    hoverLogo: newLogo
  })
}))

export default useHoverLogo