import create from 'zustand'

type MouseStudyProps = {
  status: "right" | "left",
  updateStatus: (newStatus: "left" | "right") => void;
}

const useMouseStudy = create<MouseStudyProps>(set => ({
  status: "right",
  updateStatus: (newStatus: "left" | "right") => set({ status: newStatus})
}))

export default useMouseStudy